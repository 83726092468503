import * as NetworkUtils from '@/utilities/network-utils';
import isNil from 'lodash/isNil';
import snakeCase from 'lodash/snakeCase';

export default (endpoint) => ({
    state: {
        endpointOverride: null,
        selected: {},
        list: [],
        params: '',
        requestToken: null,
        /*
        - The total number of rows  (details.total)
        - The total number of pages (details.totalPages)
      */
        details: {
            total: 0,
            totalPages: 0,
        },
        loading: false,
    },

    getters: {
        endpointOverride: (state) => state.endpointOverride,
        list: (state) => state.list,
        details: (state) => state.details,
        params: (state) => state.params,
        loading: (state) => state.loading,
        requestToken: (state) => state.requestToken,
    },

    mutations: {
        setEndpointOverride(state, endpointOverride) {
            state.endpointOverride = endpointOverride;
        },
        setSelected(state, selected) {
            state.selected = selected;
        },
        setList(state, list) {
            state.list = list;
        },
        setDetails(state, details) {
            state.details = details;
        },
        setParams(state, params) {
            state.params = params;
        },
        setRequestToken(state, requestToken) {
            state.requestToken = requestToken;
        },
        setRequestTimeout(state, requestTimeout) {
            state.requestTimeout = requestTimeout;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        emptyList(state) {
            state.list.splice(0, state.list.length);
        },
    },

    actions: {
        async fetch({commit, getters}, id) {
            commit('setLoading', true);
            commit('setSelected', {});

            let currentEndpoint = NetworkUtils.getCurrentEndpoint(endpoint, getters);

            try {
                let url = `${currentEndpoint}/${id}`;
                const response = await this._vm.$http.get(url, {
                    cancelToken: getters.requestToken?.token,
                });

                NetworkUtils.checkResponse(response, currentEndpoint);

                if (response.data.success) {
                    commit('setSelected', response.data.results);
                }

                commit('setLoading', false);
                return response.data;
            } catch (error) {
                NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
            }
        },
        async fetchList({commit, getters}, queryParams = {}) {
            commit('setLoading', true);

            // Removing empty property and casting properties name to snakeCase
            queryParams = Object.keys(queryParams)
                .filter((key) => !isNil(queryParams[key]))
                .reduce((acc, key) => ({...acc, [snakeCase(key)]: queryParams[key]}), {});

            const params = new URLSearchParams(queryParams).toString();

            let currentEndpoint = NetworkUtils.getCurrentEndpoint(endpoint, getters);

            try {
                let url = `${currentEndpoint}?${params}`;
                const response = await this._vm.$http.get(url, {
                    cancelToken: getters.requestToken?.token,
                });

                //Throws if the response has errors
                NetworkUtils.checkResponse(response, currentEndpoint);

                const details = {
                    total: response.headers['x-orion-total'] || 0,
                    totalPages: response.headers['x-orion-totalpages'] || 0,
                };

                commit('setList', response.data.results);
                commit('setDetails', details);
                commit('setParams', params);
                commit('setLoading', false);
            } catch (error) {
                NetworkUtils.alertAndRethrow(error, commit, `${currentEndpoint}?${params}`);
            }
        },
        async fetchListWithPriorParams({commit, getters}) {
            commit('setLoading', true);

            let currentEndpoint = NetworkUtils.getCurrentEndpoint(endpoint, getters);

            try {
                let url = `${currentEndpoint}?${getters.params}`;
                const response = await this._vm.$http.get(url, {
                    cancelToken: getters.requestToken?.token,
                });

                //Throws if the response has errors
                NetworkUtils.checkResponse(response, currentEndpoint);

                const details = {
                    total: response.headers['x-orion-total'] || 0,
                    totalPages: response.headers['x-orion-totalpages'] || 0,
                };

                commit('setList', response.data.results);
                commit('setDetails', details);
                commit('setLoading', false);
            } catch (error) {
                NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
            }
        },
        clearList({commit}) {
            commit('emptyList');
        },
    },
});
