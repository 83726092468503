import axios from 'axios';

export function addCancelTokenConnectionTimeout(requestTimeout) {
    let source = axios.CancelToken.source();

    setTimeout(() => {
        source.cancel();
    }, requestTimeout);

    return source.token;
}

export function alertAndRethrow(exception, commit, url) {
    commit('setLoading', false);

    throw exception;
}

export function checkResponse(response, currentEndpoint) {
    if (response.data.error) {
        throw new Error(`${currentEndpoint} - Error obj defined`);
    }

    if (response.data.results === undefined) {
        throw new Error(`${currentEndpoint}- Results undefined`);
    }

    if (response.data.success !== true) {
        throw new Error(`${currentEndpoint}- Success not true`);
    }

    /* For some reason this doesn't always work on the new
    // AWS deployments so commenting out.
    if (response.statusText !== 'OK') {
        throw new Error('Update User- Response statusText not OK');
    }*/

    if (response.status !== 200) {
        throw new Error(`${currentEndpoint}- Response not 200`);
    }
}

export function getCurrentEndpoint(endpoint, getters) {
    let currentEndpoint = endpoint;
    if (getters.endpointOverride) currentEndpoint = getters.endpointOverride;

    return currentEndpoint;
}
